<template>
  <div class="d-flex align-items-center justify-content-center flex-wrap mt-4">
    <div class="d-flex mr-3 mb-3">
      <img src="@/assets/images/forbes-thailand.png" width="100" class="mr-3 mb-3" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialProof",
};
</script>
