<template>
  <div class="bg-dark rounded-lg">
    <ui-chatbot
      @reply="triggerReply"
      :footprint-text="footprintText"
      :theme="CHATBOT_THEME"
      :messages="messages"
      :bot="DEFAULT_ASSISTANT"
      :is-text-only="true"
      :is-showing-picture="true"
      :is-shifting-messages="false"
      :is-removable="false"
      :is-sending="isSending"
      :is-loading="isLoading"
      :error-text="errorText"
      :max-number-of-characters="600000 * 2000"
      welcome-text=""
      placeholder="Enter your message here..."
      class="ui-chatbot w-100"
      style="max-width: 825px"
    />
  </div>
</template>

<script>
import UiChatbot from "ui-chatbot";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_THEME, CHATBOT_ROLES, DEFAULT_WELCOME_TEXT, DEFAULT_ASSISTANT } from "@/constants/aiChat";

import { generateKey, sleep } from "@/helpers/utils";

export default {
  name: "Chat",
  components: { UiChatbot },
  props: {
    storyboard: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    email: null,

    messages: [],

    footprintText: null,
    errorText: null,
    errorMessage: null,

    isSending: true,
    isLoading: false,
    isHidingReply: false,

    CHATBOT_MESSAGE_TYPE,
    CHATBOT_THEME,
    CHATBOT_ROLES,
    DEFAULT_WELCOME_TEXT,
    DEFAULT_ASSISTANT,
  }),
  async mounted() {
    this.isHidingReply = true;

    const { storyboard } = this;

    for await (const story of storyboard) {
      const { content, reply = null } = story;

      this.messages.push({ id: generateKey(), role: "assistant", content, reply });

      await sleep(2000);
    }

    this.footprintText = "By continuing, you agree to the Terms and Conditions and Privacy Statement.";

    this.isHidingReply = false;
    this.isSending = false;
  },
  watch: {
    isHidingReply() {
      this.handleHideReply();
    },
  },
  methods: {
    triggerReply({ content: email }) {
      this.email = email;
      this.$emit("success", { email });
    },

    handleHideReply() {
      const { isHidingReply } = this;

      const formElement = document.querySelector(".reply textarea");

      if (formElement) {
        if (isHidingReply) formElement.style.display = "none";
        else formElement.style.removeProperty("display");
      }
    },
  },
};
</script>
