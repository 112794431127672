<template>
  <div>
    <h2 class="h2 font-handwriting text-center text-capitalize">{{ headline }}</h2>
    <ul class="features text-left mt-4 helvetica p-0">
      <li v-for="value in items" :key="String(value)" class="d-flex mb-2">
        <img src="@/assets/icons/check.svg" class="icon m-0" />
        <p class="ml-3" v-html="value"></p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: {
    headline: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
