export const LANDING_TRANSCRIPTION = {
  header: {
    headline: "Meet Louis AI",
    subheadlineFrom: "Turn Any",
    subheadlineTo: "To A Transcription With Subtitles... Or Even Beyond With",
    textListFrom: ["audio", "video"],
    textListTo: ["blog post", "facebook post", "x feed"],
  },
  features: {
    headline: "What Louis AI Will Do For You",
    items: [
      "<b>A New Kind Of AI Assistant</b>: You might have seen ChatGPT before. Louis AI is a new kind of assistant, designed and specialized for business. He can interact with documents, and mimic your own style.",
      "<b>Lightning-fast Transcription</b>: Upload any audio or video and get the transcription in text or subtitles in minutes. Edit it easily if needed and download it in any format.",
      "<b>Transcript To Anything</b>: In one click, create any type of text from the transcript: summary, blog post, facebook post etc... the options are endless.",
      "<b>Three-Clicks AI Training</b>: Training an AI to write in your voice has never been that easy. All you need is a few texts and two clicks to make Louis AI write like you.",
      "<b>And So Much More</b>: Louis AI has the ability to go on the internet, analyze your competitors, write, think and help you manage your business.",
    ],
  },
  chatStoryboard: [
    { content: "Hey!" },
    {
      content:
        "If you need to transcript any audio or video files... you're in the right place! Once the transcription is done, I can even turn it into a blog post and other content.",
    },
    { content: "Start for free and transcribe your first 10 minutes.", reply: { format: "email" } },
  ],
};

export const LANDING_BLOGPOST = {
  header: {
    headline: "Meet Louis AI",
    subheadlineFrom: "Train",
    subheadlineTo: "And Create Your SEO Optimized",
    textListFrom: ["Your Own AI"],
    textListTo: ["Blog Post"],
  },
  features: {
    headline: "What Louis AI Will Do For You",
    items: [
      "<b>A New Kind Of AI Assistant</b>: You might have seen ChatGPT before. Louis AI is a new kind of assistant, designed and specialized for business. He can interact with documents, and mimic your own style.",
      "<b>Three-Clicks AI Training</b>: Training an AI to write in your voice has never been that easy. All you need is a few texts and two clicks to make Louis AI write like you.",
      "<b>Write SEO Optimized Blog Post</b>: Write your blog post optimized for a specific keyword in minutes of 600 words up to 5.000 words...",
      "<b>And So Much More</b>: Louis AI has the ability to go on the internet, analyze your competitors, write, think and help you manage your business.",
    ],
  },
  chatStoryboard: [
    { content: "Hey!" },
    {
      content: "I'm Louis AI. Feed me your own blog post, and I'll mimic the writing style to write the best blog post.",
    },
    { content: "Create your account now and start for free.", reply: { format: "email" } },
  ],
};

export const LANDING_CHATBOT = {
  features: {
    headline: "What Louis AI Will Do For You",
    items: [
      "<b>A New Kind Of AI Assistant</b>: You might have seen ChatGPT before. Louis AI is a new kind of assistant, designed and specialized for business. He can interact with documents, and mimic your own style.",
      "<b>Three-Clicks AI Training</b>: Training an AI to write in your voice has never been that easy. All you need is a few texts and two clicks to make Louis AI write like you.",
      "<b>Write SEO Optimized Blog Post</b>: Write your blog post optimized for a specific keyword in minutes of 600 words up to 5.000 words...",
      "<b>And So Much More</b>: Louis AI has the ability to go on the internet, analyze your competitors, write, think and help you manage your business.",
    ],
  },
};
