<template>
  <div>
    <b-row class="h-100 d-flex align-items-center justify-content-center">
      <b-col cols="12" md="10" lg="8" xl="6">
        <div class="text-center py-3">
          <b-img :src="require('@/assets/character/smiling.png')" width="80" height="80" alt="Smiling character" rounded fluid />
        </div>

        <data-header
          :headline="landingFiltered.header.headline"
          :subheadline-from="landingFiltered.header.subheadlineFrom"
          :subheadline-to="landingFiltered.header.subheadlineTo"
          :text-list-from="landingFiltered.header.textListFrom"
          :text-list-to="landingFiltered.header.textListTo"
        />

        <b-alert variant="danger" v-if="errorMessage" show>
          {{ errorMessage }}
        </b-alert>

        <landing-chat @success="triggerRegister" :storyboard="landingFiltered.chatStoryboard" class="p-3" />

        <data-social-proof />

        <data-features :headline="landingFiltered.features.headline" :items="landingFiltered.features.items" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { REGISTER, TRIGGER_EVENT } from "@/store/actions";

import { BRow, BCol, BImg, BAlert } from "bootstrap-vue";

import { LANDING_TRANSCRIPTION, LANDING_BLOGPOST } from "@/constants/landing";

import { getLanguageFromBrowser } from "@/helpers/utils";

import DataHeader from "@/components/Data/AnimatedHeader";
import DataFeatures from "@/components/Data/Features";
import DataSocialProof from "@/components/Data/SocialProof";

import LandingChat from "./Chat";

export default {
  name: "Landing",
  components: {
    BRow,
    BCol,
    BImg,
    BAlert,
    DataHeader,
    DataFeatures,
    DataSocialProof,
    LandingChat,
  },
  computed: {
    landingFiltered() {
      const { wizard = null } = this.$route.query;

      if (wizard === "transcription") return LANDING_TRANSCRIPTION;
      return LANDING_BLOGPOST;
    },
  },
  data: () => ({
    errorMessage: null,
  }),
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    ...mapActions("auth", { register: REGISTER }),
    async triggerRegister({ email }) {
      this.errorMessage = null;

      this.isSending = true;

      const { language: forcedLanguage } = this.$route.query;
      const language = forcedLanguage || getLanguageFromBrowser();

      if (!email) return;

      const { isSuccess, error } = await this.register({ email, language });

      if (isSuccess) {
        this.$router.push({ name: "ProjectWizard", query: { wizard: "transcription" } });
        this.isSending = false;
        return;
      }

      this.errorMessage = error;
      this.isSending = false;
    },
  },
};
</script>
