<template>
  <div>
    <h1 class="text-center text-uppercase">
      {{ headline }}
    </h1>

    <h2 class="text-center text-capitalize font-weight-normal">
      {{ subheadlineFrom }}
      <vue-typed-js :strings="textListFrom" :back-delay="3000" :loop="true" class="d-inline-block">
        <span class="typing"></span>
      </vue-typed-js>

      {{ subheadlineTo }}
      <vue-typed-js :strings="textListTo" :back-delay="3000" :loop="true" class="d-inline-block">
        <span class="typing"></span>
      </vue-typed-js>
    </h2>
  </div>
</template>

<script>
import { VueTypedJs } from "vue-typed-js";

export default {
  name: "AnimatedHeadline",
  components: { VueTypedJs },
  props: {
    headline: {
      type: String,
      default: "",
    },
    subheadlineFrom: {
      type: String,
      default: "",
    },
    subheadlineTo: {
      type: String,
      default: "",
    },

    textListFrom: {
      type: Array,
      default: () => [],
    },
    textListTo: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
h2 .typing {
  font-size: 2.3rem;
  color: #00d959;
}
</style>
